import { default as FacebookIcon } from '@ui-kit/icons/dist/icons/FacebookIcon';
import { default as InstagramIcon } from '@ui-kit/icons/dist/icons/InstagramIcon';
import { default as LinkedinIcon } from '@ui-kit/icons/dist/icons/LinkedinIcon';
import { default as YouTubeIcon } from '@ui-kit/icons/dist/icons/YouTubeIcon';
import dynamic from 'next/dynamic';
import { useLocale } from 'next-intl';

import { SOCIAL_LINKS, TRIBUNA_COM_URL } from '@utils/constants';

const KitFooter = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/Footer').then(
			m => m.Footer,
		),
	{
		ssr: true,
	},
);

const socialMedia = [
	{
		id: 'fb',
		link: SOCIAL_LINKS.FACEBOOK_LINK,
		icon: <FacebookIcon width={20} />,
	},
	{
		id: 'instagram',
		link: SOCIAL_LINKS.INSTAGRAM_LINK,
		icon: <InstagramIcon width={20} height={20} />,
	},
	{
		id: 'yt',
		link: SOCIAL_LINKS.YOUTUBE_LINK,
		icon: <YouTubeIcon width={20} height={20} />,
	},
	{
		id: 'ld',
		link: SOCIAL_LINKS.LINKEDIN_LINK,
		icon: <LinkedinIcon width={20} height={20} />,
	},
];

export default function Footer({ hideFooter, footerLinks }) {
	const currentLocale = useLocale();
	const seoLinksList = [
		{
			text: 'contact',
			link: '/contacts/',
		},
		{
			text: 'sales',
			link: 'https://tribuna.digital/',
			disableIndexing: true,
			isOuterLink: true,
		},
		{
			text: 'terms-of-use',
			link: '/terms/',
		},
		{
			text: 'privacy-policy-link',
			link: '/privacy/',
		},
		{
			text: 'cookie-policy',
			link: '/cookie-policy/',
		},
	];

	if (hideFooter) {
		return <></>;
	}

	return (
		<KitFooter
			footerLinks={footerLinks}
			socialLinks={SOCIAL_LINKS}
			localeLink={`${TRIBUNA_COM_URL}${currentLocale}`}
			socialMedia={socialMedia}
			aboutTribuna={seoLinksList}
		/>
	);
}
